<template>
	<div>
		<v-dialog :value="true" min-width="300" max-width="500" persistent noClickAnimation overlay-opacity="0" overlay-color="black" style="overflow:visible">
			<v-card color="#1e0146" dark>
				<v-card-title>
					<img src="@/assets/images/logo-planet.png" alt="Digital Attic Logo" style="max-width:500px;height:auto;">
				</v-card-title>
				<v-card-text>

					<div v-for="row in loginErrors" class="error my-5 pa-4" :key="row.message">
						{{ row.message }}
					</div>

					<!-- <div id="google-signin" :class="{saving}" class="my-5"></div> -->

					<v-form v-model="valid" @submit.prevent="handleLoginForm">
						<v-text-field v-model="emailAddress" :rules="emailRules" placeholder="Email" required single-line></v-text-field>
						<v-text-field v-model="loginPass" placeholder="Password" required type="password" single-line></v-text-field>
						<div class="buttons d-flex justify-space-between align-center">
							<v-btn type="submit" :disabled="saving" :loading="saving" color="#4b1590">Login</v-btn>
							<v-btn @click="resetPassword" text dark>Reset Password</v-btn>
						</div>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- <div class="particles">
			<div v-for="i in 75" :key="i" class="particle"></div>
			<div class="twinkling"></div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "login",

		props: {
			modal: {
				type: Object,
				required: true,
			},
		},

		data() {
			return {
				checkingLogin: true,
				loginErrors: [],
				emailAddress: "",
				loginPass: "",
				saving: false,

				valid: false,
				email: "",
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+/.test(v) || "E-mail must be valid",
				],
			};
		},

		mounted() {
			google.accounts.id.initialize({
				client_id:
					"328899777670-gevk15oq2j9shn88ileir3847m2e02km.apps.googleusercontent.com",
				auto_select: true,
				callback: this.onGoogleSignIn.bind(this),
			});

			console.log('theme', this.$vuetify.theme.defaults.dark)

			/* google.accounts.id.renderButton(
				document.querySelector('#google-signin'),
				{
					theme: 'filled_black',
					size: 'large'
				}
			); */

			google.accounts.id.prompt();
		},

		computed: {},

		methods: {
			resetPassword() {
				this.$modalService.create("resetPassword");
			},

			handleLoginForm() {
				let formData = {
					email: this.emailAddress,
					password: this.loginPass,
				};

				this.loginWithFormData(formData);
			},

			onGoogleSignIn(googleUser) {
				let formData = {
					google_token: googleUser.credential,
				};

				this.loginWithFormData(formData);
			},

			onGoogleSignInFailure() {
				console.error(arguments);
			},

			loginWithFormData(formData) {
				this.saving = true;

				this.$store.dispatch("loginUser", formData).then(
					(user) => {
						this.loginErrors = false;
						this.modal.trigger("close");
					},
					(err) => {
						google.accounts.id.disableAutoSelect();
						this.loginErrors = err;
						this.saving = false;
					}
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	// @import "~mathsass/dist/math";

	#google-signin.saving {
		opacity: 0.5;
		pointer-events: none;
	}

	/* .particles, .twinkling {
		pointer-events: none;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.particles {
		z-index: 150;
		// background: #000 url('~@/assets/images/stars.png');
	}

	@function random_range($min, $max) {
		$rand: random();
		$random_range: $min + floor($rand * (($max - $min) + 1));
		@return $random_range;
	}
	*/

	// .particle {
	// 	$total: 75;
		
	// 	width: 10px;
	// 	height: 10px;
	// 	background: white;
	// 	border-radius: 50%;
	// 	position: absolute;
	// 	z-index: 152;

	// 	@for $i from 1 through $total {
	// 		$angle: random_range(0, 10000) / 10000 * 2 * 3.141592654;
	// 		$x-angle: cos($angle);
	// 		$y-angle: sin($angle);
	// 		$scale: random(10000) / 10000;

	// 		$start-x: $x-angle * random_range(1, 10) * 0.1vw + 50vw;
	// 		$start-y: $y-angle * random_range(1, 10) * 0.1vh + 50vh;
	// 		$start-scale: 0.1;

	// 		$end-x: $x-angle * 70vw + 50vw;
	// 		$end-y: $y-angle * 70vh + 50vh;
	// 		$end-scale: $scale * 1 + $start-scale;

	// 		$animation-delay: random(30) * -1s;
	// 		$animation-duration: random_range(40, 80) * 1s;

	// 		&:nth-child(#{$i}) {
	// 			opacity: random(10000) * 0.0001;
	// 			transform: translate($start-x, $start-y) scale($start-scale);
	// 			animation: fly-#{$i} $animation-duration $animation-delay linear infinite;
	// 			@media (prefers-reduced-motion: reduce) {
	// 				animation: none;
	// 			}
	// 		}

	// 		@keyframes fly-#{$i} {
	// 			from {
	// 				transform: translate($start-x, $start-y)
	// 					scale($start-scale);
					
	// 				box-shadow: 
	// 					0 0 $start-scale * 30px $start-scale * 15px #fff, 
	// 					0 0 $start-scale * 50px $start-scale * 30px #f0f,
	// 					0 0 $start-scale * 70px $start-scale * 45px #0ff;
	// 			}

	// 			to {
	// 				transform: translate($end-x, $end-y)
	// 					scale($end-scale);

	// 				box-shadow: 
	// 					0 0 $end-scale * 30px $end-scale * 15px #fff, 
	// 					0 0 $end-scale * 50px $end-scale * 30px #f0f,
	// 					0 0 $end-scale * 70px $end-scale * 45px #0ff;
	// 			}
	// 		}
	// 	}
	// }


	/* @keyframes animate {
		0% {
			background-position: 0 0;
		}
		50% {
			background-position: 400% 0;
		}
		100% {
			background-position: 0 0;
		}
	}  */

</style>